import React, { useState, useEffect } from "react";
import { fetchAllData } from "../../../fetcher";
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import 'chartjs-adapter-date-fns';

//TODO Eventually impliment https://dndkit.com/

export const CompanyMetrics = () => {
    const [orgs, setOrgs] = useState(null);
    const [spotCount, setSpotCount] = useState(null);
    const [vehiclesTracked, setVehiclesTracked] = useState(null);
    const [cameraCount, setCameraCount] = useState({ overhead: [], lpr: [] });
    const [userCount, setUserCount] = useState(null);
    const [lotRequestCount, setLotRequestCount] = useState(null);
    const [downloadQRScans, setDownloadQRScans] = useState(null);

    const getData = async () => {
        const urls = {
            "spotCount": "/000004/spots",
            "allOrgs": "/general/organizations",
            "allUsers": "/general/spotUsers",
            "qrScans": "/general/spotMetric?metricName=qr_counter"
        }
        const response = await fetchAllData(urls, "prod");

        // Orgs
        const allOrgs = await response.allOrgs;
        const activeOrgs = [];
        for (let i in allOrgs) {
            if (allOrgs[i].active) {
                activeOrgs.push(allOrgs[i].orgID);
            }
        }

        const realActiveOrgs = activeOrgs.filter(org => org !== "000001");


        // Spots
        const totalSpots = await response.spotCount
        const activeSpots = totalSpots.filter(spot => spot.on === "true");

        // Vehicles Tracked
        const vehiclesTracked = activeSpots.filter(spot => spot.licensePlate !== "");

        // Cameras
        let overheadCameras = [];
        let lprCameras = [];

        allOrgs.forEach(org => {
            org.lots.forEach(lot => {
                lot.cameras && lot.cameras.forEach(camera => {
                    if (camera.type === "overhead") overheadCameras.push(camera)
                    if (camera.type === "lpr") lprCameras.push(camera)
                })
            })
        })

        // Users
        const allUsers = await response.allUsers;

        // Lot Requests
        const pinData = [];
        if (allUsers.length > 0) {
            allUsers.forEach((user) => {
                if (user.lotRequests) {
                    for (let i in user.lotRequests) {
                        pinData.push({
                            pinCoordinates: user.lotRequests[i].coordinates,
                            userName: user.settings.personalInfo.firstName + " " + user.settings.personalInfo.lastName,
                        })
                    }
                }
            });
        }

        // QR Scans
        const qrScans = await response.qrScans.value;

        setOrgs(realActiveOrgs.length)
        setSpotCount(activeSpots.length)
        setVehiclesTracked(vehiclesTracked.length)
        setCameraCount({ overhead: overheadCameras.length, lpr: lprCameras.length })
        setUserCount(allUsers)
        setLotRequestCount(pinData.length)
        setDownloadQRScans(qrScans)
    }

    useEffect(() => {
        getData();
    }, [])

    const GraphMetric = ({ mainTitle, data, link }) => {
        const count = data ? data.length : null;

        const signUpDates = data
            ? data.map(user => {
                const date = new Date(user.accountCreationDate);
                const startOfWeek = new Date(date);
                startOfWeek.setDate(date.getDate() - date.getDay());
                return startOfWeek;
            })
            : [];

        const validSignUpDates = signUpDates.filter(date => !isNaN(date.getTime()));
        const signUpCounts = validSignUpDates.reduce((acc, date) => {
            const dateString = date.toISOString().split('T')[0];
            acc[dateString] = (acc[dateString] || 0) + 1;
            return acc;
        }, {});

        const allDates = [];
        const currentDate = new Date(Math.min(...signUpDates.map(date => date.getTime())));
        const lastDate = new Date(Math.max(...signUpDates.map(date => date.getTime())));

        while (currentDate <= lastDate) {
            const dateString = currentDate.toISOString().split('T')[0];
            allDates.push(dateString);
            currentDate.setDate(currentDate.getDate() + 7); // Weekly intervals
        }

        const filledSignUpCounts = allDates.reduce((acc, date) => {
            acc[date] = signUpCounts[date] || 0;
            return acc;
        }, {});

        const sortedData = Object.entries(filledSignUpCounts).map(([date, count]) => ({
            x: date,
            y: count
        }));

        const lineData = {
            labels: allDates, // Continuous range of weeks
            datasets: [
                {
                    label: 'User Sign Ups',
                    data: sortedData,
                    fill: false,
                    backgroundColor: 'rgba(255,227,116,0.4)',
                    borderColor: 'rgba(255,227,116,1)',
                },
            ],
        };

        const options = {
            scales: {
                x: {
                    type: 'time',
                    time: {
                        unit: 'week',
                        tooltipFormat: 'MMM dd', // Format for tooltip
                        displayFormats: {
                            week: 'MM/dd', // Format for x-axis labels
                        },
                    },
                },
            },
            plugins: {
                legend: {
                    display: false,
                },
            },
            maintainAspectRatio: false,
        };

        return (
            <a href={link} className="rounded-xl shadow-lg border p-6 flex flex-row gap-4 grow select-none">
                <div className="min-w-fit select-none">
                    {mainTitle}
                    <h1 className={`text-2xl font-bold select-none ${count ? 'text-black' : 'text-gray-400'}`}>{count ? count : "Loading"}</h1>
                </div>
                {lineData && (
                    <div className="flex grow max-h-28 select-none" style={{ height: '200px', width: '100%' }}>
                        <Line data={lineData} options={options} />
                    </div>
                )}
            </a>
        );
    };

    const Metric = ({ mainTitle, subTitle1, data1, subTitle2, data2, link }) => {

        if (subTitle2) {
            return (
                <a href={link} className="rounded-xl shadow-lg border p-6 min-w-64 max-h-48 select-none">
                    {mainTitle}
                    <div className="flex flex-row">
                        <div className="flex flex-row items-center gap-2 select-none">
                            <h1 className={`text-2xl select-none font-bold ${data1 ? 'text-black' : 'text-gray-400'}`}>{data1 ? data1 : "Loading"}</h1>
                            <h1 className="text-sm text-gray-500 select-none">{subTitle1}</h1>
                        </div>
                        {data2 ?
                            <div className="ml-4 flex flex-row items-center gap-2 select-none">
                                <h1 className={`text-2xl select-none font-bold ${data2 ? 'text-black' : 'text-gray-400'}`}>{data2 ? data2 : "Loading"}</h1>
                                <h1 className="text-sm text-gray-500 select-none">{subTitle2}</h1>
                            </div>
                            : null}
                    </div>
                </a>
            )
        } else {
            return (
                <a href={link} target="_blank" rel="noopener noreferrer" className="grow rounded-xl shadow-lg border p-6 min-w-64 max-h-28 select-none">
                    {mainTitle}
                    <h1 className={`text-2xl font-bold select-none ${data1 ? 'text-black' : 'text-gray-400'}`}>{data1 ? data1 : "Loading"}</h1>
                </a>
            )
        }
    }

    const usersTable = 'https://us-east-1.console.aws.amazon.com/dynamodbv2/home?region=us-east-1#item-explorer?operation=SCAN&table=spotUsers';
    const orgsTable = 'https://us-east-1.console.aws.amazon.com/dynamodbv2/home?region=us-east-1#item-explorer?operation=SCAN&table=organizations';
    const spotsTable = 'https://us-east-1.console.aws.amazon.com/dynamodbv2/home?region=us-east-1#item-explorer?operation=SCAN&table=000004-spots';
    const spotMetricsTable = 'https://us-east-1.console.aws.amazon.com/dynamodbv2/home?region=us-east-1#item-explorer?operation=SCAN&table=spotMetrics';

    return (
        <div className="ml-20 p-8">
            <h1 className="text-3xl font-bold select-none">Reports</h1>
            <div className="flex flex-row flex-wrap gap-4 mt-8">
                <Metric mainTitle="Active Organizations" data1={orgs} link={orgsTable} />
                <Metric mainTitle="Spots Monitored" data1={spotCount} link={spotsTable} />
                <Metric mainTitle="Vehicles Tracked" data1={vehiclesTracked} link={spotsTable} />
                <Metric mainTitle="Active Cameras" subTitle1="Overhead" data1={cameraCount.overhead} subTitle2="LPR" data2={cameraCount.lpr} link={orgsTable} />
                <Metric mainTitle="Total Lot Requests" data1={lotRequestCount} link={usersTable} />
                <Metric mainTitle="Download QR Code Scans" data1={downloadQRScans} link={spotMetricsTable} />
                <GraphMetric mainTitle="Total Users" data={userCount} link={usersTable} />
            </div>
        </div>
    )
}

export default CompanyMetrics;