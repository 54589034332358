const prodUrl = "https://api.spotparking.app/SpotAPI";
const devUrl = "https://kk65ggj85h.execute-api.us-east-1.amazonaws.com/default/SpotAPI";
const API_KEY = process.env.REACT_APP_API_KEY;

export const fetchData = async (path, base) => {
    const activeUrl = base === "prod" ? prodUrl : devUrl;
    console.log("Fetching data from:", path);
    const url = `${activeUrl}${path}`;
    try {
        const response = await fetch(url, {
            headers: {
                'x-api-key': API_KEY
            }
        });
        const data = await response.json();
        return data;
    } catch (error) {
        // console.error('Error fetching data:', error);
        return null;
    }
};

export const fetchAllData = async (paths, base) => {
    // Use Promise.all to fetch all data concurrently
    const data = await Promise.all(Object.values(paths).map(path => fetchData(path, base)));

    const result = {};
    Object.keys(paths).forEach((key, index) => {
        result[key] = data[index];
    });

    return result;
};

export const postData = async (path, body, base) => {
    const activeUrl = base === "prod" ? prodUrl : devUrl;
    const url = `${activeUrl}${path}`;
    try {
        const response = await fetch(url, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': API_KEY
            },
            body: JSON.stringify(body)
        });
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error posting data:', error);
        return null;
    }
}