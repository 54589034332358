import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import { jwtDecode } from "jwt-decode";
import UserPool from "../../UserPool";
import Cookies from "js-cookie";
import "./login.css";
import * as AWS from 'aws-sdk/global';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import { GoogleLogin } from '@react-oauth/google';
import { toast, Toaster } from "sonner";
import { fetchAllData } from "../utility/fetcher";

export const Login = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [boxPosition, setBoxPosition] = useState("");
    const [coverPosition, setCoverPosition] = useState("");
    const [buttonMessage, setButtonMessage] = useState("Login");
    const jsCookie = Cookies.get("accessToken");

    if (jsCookie !== undefined) {
        console.log("Authenticated:", true);
        return <Navigate to="/dashboard" />;
    } else {
        console.log("Authenticated:", false);
        Cookies.remove("accessToken");
        sessionStorage.clear();

        const allowedPaths = ["/login", "/contact-us", "/reset-password"];
        if (!allowedPaths.includes(window.location.pathname)) {
            window.location.href = "/login";
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log("Form submitted");

        const tempUsername = username.trim().toLowerCase();
        const tempPassword = password.trim();
        const user = new CognitoUser({
            Username: tempUsername,
            Pool: UserPool
        });

        const authDetail = new AuthenticationDetails({
            Username: tempUsername,
            Password: tempPassword
        });

        user.authenticateUser(authDetail, {
            onSuccess: async (data) => {
                console.log("Success:", data);
                setBoxPosition("-110");

                let allowed = false
                const urls = { "auth": `/public/validateUser?email=${username.trim().toLowerCase()}&orgName=Spot Parking&platform=godPortal` };
                const response = await fetchAllData(urls);

                // Check if user is allowed to access the application
                allowed = response.auth;
                if (!allowed) {
                    setErrorMessage("User not allowed to access this application.");
                    setButtonMessage("Login");
                    return;
                } else {
                    localStorage.setItem('organization', "Spot Parking");
                    Cookies.set("token", data.getAccessToken().getJwtToken(), { expires: 7 });
                    window.location.href = "/";
                    const decodedToken = jwtDecode(data.getAccessToken().getJwtToken(), { header: true });
                    console.log("Decoded Token:", decodedToken.kid);
                };
            },
            onFailure: (err) => {
                console.error("Failure:", err);
                setErrorMessage(err.message);
            },
            newPasswordRequired: (data) => {
                window.location.href = "/reset-password";
                console.log("New password required:", data);
            }
        });
    }

    AWS.config.update({
        region: 'us-east-1', // Replace with your AWS region
    });

    // Google Sign-In handler
    const handleGoogleSignIn = async (credentialResponse) => {
        try {
            const googleToken = credentialResponse.credential; // Google token
            const identityPoolId = "us-east-1:505c99c4-e14b-407c-9685-38718acca036"; // Your Identity Pool ID

            // Construct the Google token request to Cognito Identity Pool
            const logins = {
                'accounts.google.com': googleToken, // Google token as a federated identity provider
            };

            const cognitoIdentity = new AWS.CognitoIdentity();
            const params = {
                IdentityPoolId: identityPoolId, // Replace with your Identity Pool ID
                Logins: logins,  // Provide the Google token for authentication
            };

            cognitoIdentity.getId(params, (err, data) => {
                if (err) {
                    console.error("Error getting identity ID", err);
                    setErrorMessage("Google Sign-In failed");
                } else {
                    // To assume a role and get AWS credentials (optional, based on your app):
                    const identityId = data.IdentityId;
                    const credentialsParams = {
                        IdentityId: identityId,
                        Logins: logins,
                    };

                    cognitoIdentity.getCredentialsForIdentity(credentialsParams, async (error, credentialsData) => {
                        if (error) {
                            console.error("Error getting credentials", error);
                        } else {
                            const { AccessKeyId, SecretKey, SessionToken } = credentialsData.Credentials;
                            console.log("Cognito credentials:", JSON.stringify(credentialsData));
                            let email = "";

                            AWS.config.credentials = new AWS.CognitoIdentityCredentials({
                                IdentityId: identityId,
                                Logins: logins,
                            });

                            AWS.config.credentials.get(async (err) => {
                                if (err) {
                                    console.error("Error getting AWS credentials", err);
                                } else {
                                    const idToken = googleToken; // Use the Google token as idToken
                                    const decodedToken = JSON.parse(atob(idToken.split('.')[1]));
                                    email = decodedToken.email;
                                    localStorage.setItem('email', email);
                                    console.log(`\x1b[34mEMAIL: ${email}\x1b[0m`);

                                    let allowed = false
                                    const urls = { "auth": `/public/validateUser?email=${email.trim().toLowerCase()}&orgName=Spot Parking&platform=godPortal` };
                                    const response = await fetchAllData(urls);

                                    // Check if user is allowed to access the application
                                    allowed = response.auth;
                                    if (!allowed) {
                                        setErrorMessage("User not allowed to access this application.");
                                        setButtonMessage("Login");
                                        return;
                                    } else {
                                        // Save tokens or take appropriate action
                                        localStorage.setItem('accessKey', AccessKeyId);
                                        localStorage.setItem('secretKey', SecretKey);
                                        localStorage.setItem('sessionToken', SessionToken);
                                        localStorage.setItem('organization', "Spot Parking");

                                        // Optionally, you can also save the token in cookies for future use
                                        Cookies.set("token", SessionToken, { expires: 7 });

                                        // Redirect to home page or next route
                                        window.location.href = "/";
                                    };
                                };
                            });
                        };
                    });
                };
            });
        } catch (error) {
            console.error("Google Sign-In failed:", error);
            setErrorMessage("Failed to sign in with Google");
        }
    };

    const square = (width, height, x, y, rotation) => {
        const newY = boxPosition ? boxPosition : y;

        return (
            <div className="background-square" style={{
                width: `${width * .75}%`,
                height: `${height * 1.25}%`,
                transform: `rotate(${rotation}deg)`,
                top: `${newY}vh`,
                left: `${x}vw`,
            }}></div>
        );
    }

    const coverer = () => {
        const newY = coverPosition ? coverPosition : "100vh";

        return (
            <div className="coverer" style={{ top: newY }}></div>
        )
    }

    return (
        <div className=" overflow-hidden relative fcc h-screen" style={{ background: "linear-gradient(to top, #FFE374, #474747)" }}>
            {coverer()}
            <div className="h-fit w-fit min-w-96 rounded-2xl p-4 bg-white shadow-md fcc z-50">
                <h1 style={{ color: "#323232", fontSize: "50px", fontWeight: "bold", marginBottom: "10px", marginTop: "-15px" }}>Login</h1>
                <div style={{ width: "75%" }}>
                    <form onSubmit={handleSubmit}>
                        <label>
                            <div className="login-title">
                                Email
                            </div>
                            <input
                                type="text"
                                value={username}
                                className="login-input"
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </label>
                        <br />
                        <label>
                            <div className="login-title">
                                Password
                            </div>
                            <input
                                type="password"
                                value={password}
                                className="login-input"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </label>
                        {errorMessage !== "" ? <p className="login-error-message text-center">{errorMessage}</p> : null}
                        <br />
                        <br />
                        <div className="flex flex-col gap-5 mb-3 justify-center items-center">
                            <div
                                className={`w-fit px-8 h-12 fcc cursor-pointer  bg-gradient-to-r from-spotYellow to-yellow-200 rounded-2xl font-bold text-2xl transition-all duration-100 select-none hover:bg-gradient-to-r hover:from-white hover:to-white hover:text-spotYellow hover:border-2 hover:border-spotYellow hover:shadow-lg`}
                                onClick={handleSubmit}
                                aria-label="Login Button"
                            >
                                {buttonMessage}
                            </div>

                            {/* Google Sign In Button */}
                            <GoogleLogin
                                shape="circle"
                                onSuccess={handleGoogleSignIn}
                                onError={() => {
                                    console.log('Login Failed');
                                }}
                            />
                        </div>
                    </form>
                </div>
            </div>
            <div style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                overflow: "hidden",
            }}>
                {square(70, 50, 20, -25, 40)}
                {square(70, 70, 0, 80, -20)}
                {square(70, 70, 90, 40, -20)}
            </div>
        </div>
    );
}