import React, { useEffect, useState } from "react";
import { fetchData, postData } from "../../fetcher";

export const Users = () => {
    const [allUsers, setAllUsers] = useState([]);
    const [ogUsers, setOgUsers] = useState([]);

    useEffect(() => {
        const getUsers = async () => {
            const response = await fetchData("/general/spotUsers", "prod");
            response.sort((a, b) => a.settings.personalInfo.lastName.localeCompare(b.settings.personalInfo.lastName));
            setOgUsers(response);
            setAllUsers(response);
        }

        getUsers();
    }, []);

    const handleCheckboxChange = async (index, app, permission, checked) => {
        const updatedUsers = [...allUsers];
        updatedUsers[index].apps[app][permission] = checked;
        setAllUsers(updatedUsers);

        const changedUser = updatedUsers[index];
        try {
            const response = await postData("/general/spotUsers/", changedUser, "prod");
            console.log(response);
        } catch (error) {
            console.error("Error updating user:", error);
        }
    }

    return (
        <div className="ml-32 mt-10 mr-10 flex flex-col justify-center items-center">
            <div className="w-full flex flex-row justify-between items-center">
                <h1 className="text-3xl font-bold">Users</h1>
                <input
                    type="text"
                    placeholder="Search users..."
                    className="border px-4 py-2 mb-4 rounded-md outline-spotYellow"
                    onChange={(e) => {
                        const searchTerm = e.target.value.toLowerCase();
                        if (searchTerm === "") {
                            setAllUsers(ogUsers);
                        } else {
                            const filteredUsers = ogUsers.filter(user =>
                                user.settings.personalInfo.firstName.toLowerCase().includes(searchTerm) ||
                                user.settings.personalInfo.lastName.toLowerCase().includes(searchTerm) ||
                                user.email.toLowerCase().includes(searchTerm)
                            );
                            setAllUsers(filteredUsers);
                        }
                    }}
                />
            </div>

            <table className="table-auto mr-10">
                <thead>
                    <tr>
                        <th className="px-4 py-2">Name</th>
                        <th className="px-4 py-2">Email</th>
                        <th className="px-4 py-2">Creation Date</th>
                        <th className="px-4 py-2">Permissions</th>
                    </tr>
                </thead>
                <tbody>
                    {allUsers.map((user, index) => (
                        <tr key={index}>
                            <td className="border px-4 py-2">{user.settings.personalInfo.firstName} {user.settings.personalInfo.lastName}</td>
                            <td className="border px-4 py-2">{user.email}</td>
                            <td className="border px-4 py-2">{new Date(user.accountCreationDate).toLocaleDateString()}</td>
                            <td className="border px-4 py-2">
                                <table className="table-auto">
                                    <thead>
                                        <tr>
                                            <th className="px-4 py-2">App</th>
                                            {Object.keys(user.apps[Object.keys(user.apps)[0]]).map((permission, i) => (
                                                <th key={i} className="px-4 py-2">{permission.charAt(0).toUpperCase() + permission.slice(1)}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.entries(user.apps).map(([app, permissions], i) => (
                                            <tr key={i}>
                                                <td className="border px-4 py-2">{app}</td>
                                                {Object.entries(permissions).map(([permission, value], j) => (
                                                    <td key={j} className="border px-4 py-2">
                                                        <input
                                                            type="checkbox"
                                                            checked={value}
                                                            onChange={(e) => handleCheckboxChange(index, app, permission, e.target.checked)}
                                                        />
                                                    </td>
                                                ))}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div >
    )
}
